<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" label="影院组织" name="organizationId">
						<a-select placeholder="请选择影院组织" v-model:value="formState.organizationId" style="width: 190px;" @change="getAllCinemaList">
							<!-- <a-select-option :value="0">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="所属影院" name="cinemaId">
						<a-select placeholder="请选择所属影院" v-model:value="formState.cinemaId" style="width: 190px;" @change="getCinemaId">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id" > {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="放映时间">
						<a-range-picker v-model:value="time" :showTime="{ hideDisabledOptions: true, defaultValue:[moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')] }"/>
					</a-form-item>
				</a-row>
				<a-row v-show="showAll">
					<a-form-item class="ui-form__item" label="订单来源" name="source">
						<a-select v-model:value="formState.source" style="width: 190px;" placeholder="请选择订单来源">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option :value="1">微信小程序</a-select-option>
							<a-select-option :value="2">抖音小程序</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="订单号" name="orderNo">
						<a-input placeholder="请输入订单号" v-model:value="formState.orderNo"></a-input>
					</a-form-item>

					<a-form-item class="ui-form__item" label="商户流水号" name="outTradeNo">
						<a-input placeholder="请输入商户流水号" v-model:value="formState.outTradeNo"></a-input>
					</a-form-item>

					<a-form-item class="ui-form__item" label="服务费流水号" name="refundOutTradeNo">
						<a-input placeholder="请输入服务费流水号" v-model:value="formState.refundOutTradeNo"></a-input>
					</a-form-item>

					<a-form-item class="ui-form__item" label="影片名称" name="filmName">
						<a-input placeholder="请输入影片名称" v-model:value="formState.filmName"></a-input>
					</a-form-item>

					<a-form-item class="ui-form__item" label="用户手机" name="phone">
						<a-input placeholder="请输入用户手机" v-model:value="formState.phone"></a-input>
					</a-form-item>

					<a-form-item class="ui-form__item" label="购票模式" name="buyTicketMode">
						<a-select v-model:value="formState.buyTicketMode" style="width: 190px;" placeholder="请选择购票模式">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option :value="1">票务系统出票</a-select-option>
							<a-select-option :value="2">云端系统出票</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="取票状态" name="ticketStatus">
						<a-select style="width: 190px;" placeholder="选择取票状态" v-model:value="formState.ticketStatus">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option :value="1">未取票</a-select-option>
							<a-select-option :value="3">已取票</a-select-option>
							<a-select-option :value="4">已退票</a-select-option>
							<a-select-option :value="5">未退票</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="平台发起提现" name="isSubLedger">
						<a-select style="width: 190px;" placeholder="选择取票状态" v-model:value="formState.isSubLedger">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option :value="1">已发起</a-select-option>
							<a-select-option :value="0">未发起</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="销售时间">
						<a-range-picker v-model:value="payTime" :showTime="{ hideDisabledOptions: true, defaultValue:[moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')] }" :disabledDate="disabledDate"/>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
                        <span v-permission="['report_ticket_sale_export']">
							<ExportReport type="ticketStatementDetail" :searchData="searchData"></ExportReport>
						</span>
						<a style="margin-left: 20px;" @click="showAll = !showAll">
							{{ showAll ? '收起' :'展开' }}
							<Icon v-show="!showAll" icon="DownOutlined"></Icon>
							<Icon v-show="showAll" icon="UpOutlined"></Icon>
						</a>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list" :scroll="{ x: 3500 }" tableLayout="auto">
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.showStartTime, 1) }}
						</template>
						<template v-if="column.key === 'day'">
							星期{{ ['日', '一', '二', '三', '四', '五', '六'][moment(record.showStartTime *1000).day()] }}
						</template>
						<template v-if="column.key === 'orderInfo'">
							<p>订单号：<span style="color: #999;">{{ record.orderNo }}</span></p>
							<p>商户流水号：<span style="color: #999;">{{ record.outTradeNo }}</span></p>
						</template>
						<template v-if="column.key === 'source'">
							<div>
								<a-tag color="green" v-if="record.source === 1">
									微信小程序
								</a-tag>
                <a-tag color="green" v-if="record.source === 2">
                  抖音小程序
                </a-tag>
                <a-tag color="green" v-if="record.source === 3">
                  PC购票
                </a-tag>
<!--								<a-tag color="blue" v-else> 抖音小程序 </a-tag>-->
							</div>
						</template>

						<template v-if="column.key === 'seat'">
							<span v-for="(item, i) in record.cinemaSeatsJsonVOS" :key="i">
								<a-tag style="margin-bottom: 6px;" color="blue" v-if="item">
									{{ item.rowId }}排 {{ item.colId }}座
								</a-tag>
							</span>
						</template>
						<template v-if="column.key === 'payType'">
							<a-tag style="font-size: 12px;" :color="['pink', 'orange', 'green', 'purple', 'cyan', 'blue', 'red'][record.payType - 1]">
                {{ ['兑换券(旧)', '年卡', '次卡', '微信', '兑换券', '线上会员卡', '抖音券', '影城会员卡', '第三方线下', 'PC现金'][record.payType - 1] }}支付
							</a-tag>
							<div style="margin-top: 6px;" v-if="record.payType === 2 || record.payType === 3">卡号：<span style="color: #888;">{{ record.cardNumber }}</span></div>
							<div style="margin-top: 6px;" v-if="record.payType === 1 || record.payType === 5">券号：
								<div style="color: #888;" v-for="(item, i) in record.barcodes" :key="item">
									{{ item }}
									<span v-if="i < record.barcodes.length - 1">、</span>
								</div>
							</div>
							<div v-if="record.payType === 7">
								<div v-if="record.tikTokTicketOrderInfoVOS?.length">
									<div style="border: solid 1px #eee;border-radius: 8px;padding: 6px 10px;"
										v-for="item in record.tikTokTicketOrderInfoVOS" :key="item.barcode">
										<p style="padding: 0;margin: 0;">抖音券号：{{ item.barcode || '-' }}</p>
										<p style="padding: 0;margin: 0;">抖音券名称：{{ item.title || '-' }}</p>
										<p style="padding: 0;margin: 0;">抖音商品sku：{{ item.goodsSku || '-' }}</p>
									</div>
								</div>
							</div>
						</template>
						<template v-if="column.key === 'film'">
							{{ record.filmName }}
							<!-- 场次：{{ transDateTime(record.showStartTime) }} - {{ transDateTime(record.showEndTime) }} -->
						</template>
						<template v-if="column.key === 'showTime'">
							<div>{{ transDateTime(record.showStartTime) }}</div>
							 至
							<div>{{ transDateTime(record.showEndTime) }}</div>
						</template>
						<template v-if="column.key === 'userInfo'">
							<div>用户昵称：{{ record.nickname || '--' }}</div>
              <div v-if="record.phone != '88888888888'">手机号：{{ record.phone }}</div>
						</template>
						<template v-if="column.key === 'status'">
							<div v-if="record.status !== 'AFTERSALE_FINISH'">
								{{ ['未取票', '取票中', '已取票'][record.ticketStatus - 1] }}
								<div v-if="record.ticketStatus === 3">
									{{ transDateTime(record.printTime) }}
								</div>
							</div>
							<div v-else>
								已退票
							</div>
						</template>
						<template v-if="column.key === 'refundStatus'">
							<div v-if="record.status === 'AFTERSALE_FINISH'">已退票</div>
							<div v-else>未退票</div>
							<div v-if="record.status === 'AFTERSALE_FINISH'" style="font-size: 12px;color: #999;">
								<div>操作人员：{{ record.adminUser || '--' }}</div>
								<div>时间：{{ transDateTime(record.refundTime) }}</div>
								<div>已退款{{ record.refund }}元<span v-if="record.description">,{{ record.description }}</span></div>
							</div>
						</template>
						<template v-if="column.key === 'service'">
							<div v-if="record.status === 'AFTERSALE_FINISH' || record.afterStatus === 1 || record.afterStatus === 6">
								<div>服务费：￥{{ record.serviceFee || 0 }}</div>
								<div>流水号：<span style="color: #888;">{{ record.refundOutTradeNo || '-' }}</span> </div>
							</div>
							<div v-else>
								--
							</div>
						</template>
						<template v-if="column.key === 'buyTicketMode'">
							<a-tag :color=" record.buyTicketMode === 1 ? '#2db7f5' : 'cyan'">
								{{ record.buyTicketMode === 1 ? '票务系统出票' : '云端系统出票' }}
							</a-tag>
						</template>
						<template v-if="column.key === 'saleTime'">
							{{ transDateTime(record.createTime) }}
						</template>
						<template v-if="column.key === 'isSubLedger'">
							<span v-if="record.source === 2">无</span>
							<span v-else>
								{{ record.isSubLedger ? '已发起' :'未发起' }}
							</span>
						</template>
						<template v-if="column.key === 'afterStatus'">
							<div v-if="record.afterStatus === 1">待审核</div>
							<div v-else-if="record.afterStatus === 4">已拒绝</div>
							<div v-else-if="record.afterStatus === 6">已同意</div>
							<div v-else>未发起售后</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-permission="['report_ticket_sale_refund']" v-if="record.status !== 'AFTERSALE_FINISH'" type="link" @click="onRefund(record)">申请退票</a-button>
						</template>
					</template>

					<template #summary v-if="list && list.length">
						<a-table-summary-row>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell>
								合计：{{ total.seatsNum || 0 }}
							</a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell>
								合计：{{ total.originalPrice || 0 }}
							</a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell>
								服务费合计：{{ total.serviceFeeTotal || 0 }}
							</a-table-summary-cell>
						</a-table-summary-row>
					</template>
				</a-table>
			</div>

			<a-modal v-model:visible="refundVisible" title="退款" @cancel="onRefundCancel" @ok="onRefundOk">
				<a-spin :spinning="loading">
					<a-form ref="refundForm" name="refundForm" :model="refundData">
						<a-form-item label="退款金额" name="refund" extra="注:退款金额不允许大于实际付款金额" :rules="[{required: true, message: '请输入退款金额'}]">
							<a-input-number v-model:value="refundData.refund" :min="0" :max="detail.payment" placeholder="请输入">
							</a-input-number>
						</a-form-item>

						<a-form-item label="退款备注" name="remark" :rules="[{required: true, message: '请输入退款备注'}]">
							<a-textarea v-model:value="refundData.remark" placeholder="请输入退款备注"></a-textarea>
						</a-form-item>
					</a-form>
				</a-spin>
			</a-modal>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import { ticketOrderList, ticketOrderRefund } from '@/service/modules/report.js';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import ExportReport from '@/components/exportReport/exportReport.vue';

	export default {
		components: {
			Icon,
			ExportReport
		},
		data() {
			return {
				loading: false,
				showAll: false,
				searchData: {},
				formState: {
					// organizationId: 0,
					cinemaId: 0,
					payType: 0,
					source: 0,
					buyTicketMode: 0,
					ticketStatus: '',
					isSubLedger: ''
				},
				time: [],
				payTime: [],
				list: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
        columns: [{
          title: '放映日期',
          key: 'createTime',
          width: 120
        }, {
          title: '星期',
          key: 'day',
          width: 100
        }, {
          title: '所属影院',
          dataIndex: 'cinemaName'
        }, {
          title: '订单信息',
          key: 'orderInfo',
          width: 350
        }, {
          title: '订单来源',
          key: 'source',
          width: 120,
          align: 'center'
        }, {
          title: '座位',
          key: 'seat',
          width: 300
        }, {
          title: '座位数量',
          dataIndex: 'seatsNum',
          width: 120
        }, {
          title: '座位价格(元)',
          dataIndex: 'price',
          width: 120
        }, {
          title: '票房价格',
          dataIndex: 'originalPrice',
          width: 120
        }, {
          title: '影片信息',
          key: 'film'
        }, {
          title: '放映时间',
          key: 'showTime',
          width: 200
        }, {
          title: '用户信息',
          key: 'userInfo',
          width: 200
        }, {
          title: '后台出票人',
          key: 'pcCreateUserName',
          width: 200
        }, {
          title: '购票模式',
          key: 'buyTicketMode',
          width: 120
        }, {
          title: '取票状态',
          key: 'status',
          width: 180
        }, {
          title: '销售时间',
          key: 'saleTime',
          width: 180
        }, {
          title: '退票信息',
          key: 'refundStatus',
          width: 200
        }, {
          title: '服务费信息',
          key: 'service',
          width: 250
        }, {
          title: '平台发起提现',
          key: 'isSubLedger',
          width: 120
        }, {
          title: '售后状态',
          key: 'afterStatus',
          width: 120
        }],
				organizationList: [],
				cinemaAllList: [],
				total:{},
				refundVisible: false,
				refundData: {},
				detail: {}
			}
		},
		created(){
			// this.onSearch();
			this.getOrganizationList();
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.source = this.searchData.source ? this.searchData.source : undefined;
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				this.searchData.payType = this.searchData.payType ? this.searchData.payType : undefined;
				this.searchData.isSubLedger = this.searchData.isSubLedger !== '' ? this.searchData.isSubLedger : undefined;
				this.searchData.buyTicketMode = this.searchData.buyTicketMode ? this.searchData.buyTicketMode : undefined;
				if (this.time && this.time.length) {
					this.searchData.showStartTime = this.moment(this.time[0]).unix();
					this.searchData.showEndTime = this.moment(this.time[1]).unix();
				}
				if (this.payTime && this.payTime.length) {
					this.searchData.startTime = this.moment(this.payTime[0]).unix();
					this.searchData.endTime = this.moment(this.payTime[1]).unix();
				}
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.cinemaAllList = [];
				this.time = [];
				this.payTime = [];
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
			},
			async getData() {
				const postData = {
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					...this.searchData,
				}
				this.loading = true;
				try {
					let {data} = await ticketOrderList(postData);
					this.loading = false;
					this.total = data.list[0];
					this.list = data.list.splice(1);
					this.pagination.total = data.totalCount;
				} catch (error) {
					this.loading = false;
					console.log(error);
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				// this.formState.cinemaId = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			onRefund(item) {
				this.detail = item;
				this.refundData = {};
				this.refundVisible = true;
			},
			onRefundCancel() {
				this.$refs.refundForm.resetFields();
				this.refundVisible = false;
				this.detail = {};
				this.refundData = {};
			},
			onRefundOk() {
				this.$refs.refundForm.validateFields().then(() => {
					this.$confirm({
						title:'提示',
						content: '确定需要退票吗？',
						onOk: async ()=> {
							this.loading = true;
							try {
								let ret = await ticketOrderRefund({
									orderId: this.detail.id,
									refund: this.refundData.refund,
									description: this.refundData.remark
								});
								this.loading = false;
								if (ret.code === 200) {
									this.$message.success('退票操作成功');
									this.refundVisible = false;
									this.getData();
								}
							} catch(e) {
								this.loading = false;
								console.log(e)
							}
						}
					})
				});
			},
			getCinemaId(value){
				this.cinemaIds = value;
			},
			disabledDate(current) {
                return current && current >= this.moment().endOf('day');
            }

		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
