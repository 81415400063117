<template>
    <div>
        <Header :title="isEdit ? '编辑影城会员卡充值活动' :'新增影城会员卡充值活动'" @back="onBack(false)"></Header>
        <a-spin :spinning="loading">
            <a-form style="margin-top: 20px;" ref="form" name="form" :model="modelRef" scrollToFirstError
                    :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }" @finish="onSubmit">

                <a-form-item name="organizationId" label="影院组织" :rules="[{ required: true, message: '必选项不允许为空' }]">
                    <a-select :disabled="isSee" placeholder="请选择" v-model:value="modelRef.organizationId" @change="(id) => getAllCinemaList(id)">
                        <a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item name="cinemaId" label="所属影院" :rules="[{ required: true, message: '必选项不允许为空' }]">
                    <a-select :disabled="isSee" placeholder="请选择" v-model:value="modelRef.cinemaId" @change="(id) => getCardPolicyList(id)">
                        <a-select-option v-for="item in cinemaList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
                    </a-select>
                </a-form-item>

                <!-- <a-form-item label="是否通用" name="isUniversal" :rules="[{ required: true, message: '必选项不允许为空' }]">
                    <a-radio-group v-model:value="modelRef.isUniversal" :disabled="isSee">
                        <a-radio :value="1">是</a-radio>
                        <a-radio :value="0">否</a-radio>
                    </a-radio-group>
                </a-form-item> -->

                <!-- <a-form-item v-if="modelRef.isUniversal === 0" name="cardRechargeActivityList" label="适用卡等级" :rules="[{ required: true, message: '必选项不允许为空' }]">
                    <a-select :disabled="isSee" placeholder="请选择" mode="multiple" v-model:value="modelRef.cardRechargeActivityList">
                        <a-select-option v-for="item in cardAllList" :vlaue="item.id" :key="item.id"> {{ item.title }}</a-select-option>
                    </a-select>
                </a-form-item> -->

                <a-form-item label="活动名称" name="name" :rules="[{ required: true, message: '必填项不允许为空' }]">
                    <a-input :disabled="isSee" v-model:value="modelRef.name" placeholder="请输入" />
                </a-form-item>

                <a-form-item label="充值金额" name="rechargeAmount" :rules="[{ required: true, message: '必填项不允许为空' }]">
                    <a-input-number :disabled="isSee" v-model:value="modelRef.rechargeAmount" :min="0" :precision="2" placeholder="请输入" /> 元
                </a-form-item>

                <a-form-item label="充值到账金额" name="arrivalAmount" :rules="[{ required: true, message: '必填项不允许为空' }]">
                    <a-input-number :disabled="isSee" v-model:value="modelRef.arrivalAmount" :min="0" :precision="2" placeholder="请输入" /> 元
                </a-form-item>

                <a-form-item label="是否长期有效" name="isLong" >
                    <a-radio-group :disabled="isSee" v-model:value="modelRef.isLong">
                        <a-radio :value="1">是</a-radio>
                        <a-radio :value="0">否</a-radio>
                    </a-radio-group>
                </a-form-item>

                <a-form-item v-if="modelRef.isLong === 0" label="活动有效期" name='time' :rules="[{required: true, message: '必填项不允许为空'}]">
                    <a-range-picker :disabled="isSee" v-model:value="modelRef.time"/>
                </a-form-item>

                <a-form-item label="是否含有券" name="isCoupon" :rules="[{ required: true, message: '必选项不允许为空' }]">
                    <a-radio-group :disabled="isSee" v-model:value="modelRef.isCoupon">
                        <a-radio :value="1">是</a-radio>
                        <a-radio :value="0">否</a-radio>
                    </a-radio-group>
                </a-form-item>

                <div v-if="modelRef.isCoupon">
                    <a-form-item name="ticketBatchNum" label="兑换券发放数量">
                        <a-input-number v-model:value="modelRef.ticketBatchNum" :min="0" :disabled="isSee" :precision="0" placeholder="请输入"></a-input-number> 张
                    </a-form-item>
                    <a-form-item label="兑换券批次" :labelCol="{span: 4, xxl: 3}" :wrapperCol="{span: 20, xxl: 21 }">
                        <couponRecord v-model:value="modelRef.ticketBatchId" :id="modelRef.cinemaId" :couponType="1" :selectItem="exchangeList" :disabled="isSee" from="goods" @change="(data) => onCouponChange(1, data)"></couponRecord>
                        <div v-show="modelRef.ticketBatchId.length">
                            <a-table rowKey="id" :columns="exchangeColumns" :dataSource="exchangeList" :pagination="false" :scroll="{ x: 1200 }">
                                <template #bodyCell="{ column, record }">
                                    <template v-if="column.key === 'createdTime'">
                                        {{ transDateTime(record.createTime) }}
                                    </template>
                                    <template v-if="column.key === 'time'">
                                        <div v-if="record.validPeriodType == 7">
                                        	领取后{{ record.period }}天内有效
                                        </div>
                                        <div v-else>
                                        	{{ transDateTime(record.receiveStartTime, 1) }} - {{ transDateTime(record.receiveEndTime, 1) }}
                                        </div>
                                    </template>
                                    <template v-if="column.key === 'redeemedCount'">
                                        {{ record.importCount - record.redeemedCount }}
                                    </template>
                                    <template v-if="column.key === 'isHoliday'">
                                        <div v-if="record.isHoliday">通兑</div>
                                        <div v-else>非通兑</div>
                                    </template>
                                    <template v-if="column.key === 'action'">
                                        <a-button :disabled="isSee" type="line" @click="onDelCoupon(1, index)">删除</a-button>
                                    </template>
                                </template>
                            </a-table>
                        </div>
                    </a-form-item>
                    <a-form-item name="snackBatchNum" label="卖品券发放数量">
                        <a-input-number v-model:value="modelRef.snackBatchNum" :min="0" :precision="0" :disabled="isSee" placeholder="请输入"></a-input-number> 张
                    </a-form-item>
                    <a-form-item label="卖品券批次" :labelCol="{span: 4, xxl: 3}" :wrapperCol="{span: 20, xxl: 21 }">
                        <couponRecord v-model:value="modelRef.snackBatchId" :couponType="3" :id="modelRef.cinemaId" :selectItem="snackList" :disabled="isSee" from="goods" @change="(data) => onCouponChange(3, data)"></couponRecord>
                        <div v-show="modelRef.snackBatchId.length">
                            <a-table rowKey="id" :columns="snackColumns" :dataSource="snackList" :pagination="false" :scroll="{ x: 1000 }">
                                <template #bodyCell="{ column, record }">
                                    <template v-if="column.key === 'createdTime'">
                                        {{ transDateTime(record.createTime) }}
                                    </template>
                                    <template v-if="column.key === 'time'">
                                        <div v-if="record.validPeriodType == 7">
                                        	领取后{{ record.period }}天内有效
                                        </div>
                                        <div v-else>
                                        	{{ transDateTime(record.receiveStartTime, 1) }} - {{ transDateTime(record.receiveEndTime, 1) }}
                                        </div>
                                    </template>
                                    <template v-if="column.key === 'redeemedCount'">
                                        {{ record.importCount - record.redeemedCount }}
                                    </template>
                                    <template v-if="column.key === 'action'">
                                        <a-button :disabled="isSee" type="line" @click="onDelCoupon(3, index)">删除</a-button>
                                    </template>
                                </template>
                            </a-table>
                        </div>
                    </a-form-item>
                </div>

                <a-form-item label="是否启用" name="isDisabled" :rules="[{ required: true, message: '必填项不允许为空' }]">
                    <a-radio-group :disabled="isSee" v-model:value="modelRef.isDisabled">
                        <a-radio :value="0">启用</a-radio>
                        <a-radio :value="1">禁用</a-radio>
                    </a-radio-group>
                </a-form-item>

                <a-form-item>
                    <div style="text-align: center;">
                        <a-button type="primary" v-if="!isSee" html-type="submit">提交</a-button>
                        <a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
                    </div>
                </a-form-item>
            </a-form>
        </a-spin>
    </div>
</template>

<script>
	import Header from '@/components/header/header.vue';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import { getCardPolicyList, saveRechargeActivity, getRechargeActivityDetail, updateRechargeActivity } from '@/service/modules/coupon.js';
	import couponRecord from '@/components/couponRecord/index.vue';
	export default {
		components: {Header,couponRecord},
		props: {
			isEdit: {
				type: Boolean,
				default: false
			},
			isSee: {
				type: Boolean,
				default: false
			},
			id: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				loading: false,
				modelRef: {
				    isUniversal: 0,
				    isLong: 0,
					isDisabled: 0,
					isCoupon: 0,
					type:2,
					ticketBatchId: [],
					snackBatchId: [],
					ticketBatchNum: 0,
					ticketBatchNum: 0
				},
				organizationList: [],
				cinemaList: [],
				cardAllList: [],
				exchangeList: [],
                exchangeColumns: [{
                    title: '批次号',
                    dataIndex: 'batchNo'
                }, {
                    title: '批次名称',
                    dataIndex: 'name'
                }, {
                    title: '有效期',
                    key: 'time'
                }, {
                    title: '是否通兑',
                    key: 'isHoliday',
                    width: 100
                }, {
                    title: '补差金额（元）',
                    dataIndex: 'diffPrice',
                    width: 130
                }, {
                    title: '兑换券数量',
                    dataIndex: 'importCount',
                    width: 130
                }, {
                    title: '已绑定数量',
                    dataIndex: 'bindCount',
                    width: 130
                }, {
                    title: '未兑换数量',
                    key: 'redeemedCount',
                    width: 130
                }, {
                    title: '操作',
                    key: 'action',
                    fixed: 'right',
                    width: 100
                }],
                snackList: [],
                snackColumns: [{
                    title: '批次号',
                    dataIndex: 'batchNo'
                }, {
                    title: '有效期',
                    key: 'time'
                }, {
                    title: '卖品券数量',
                    dataIndex: 'importCount',
                    width: 130
                }, {
                    title: '已绑定数量',
                    dataIndex: 'bindCount',
                    width: 130
                }, {
                    title: '未兑换数量',
                    key: 'redeemedCount',
                    width: 130
                }, {
                    title: '操作',
                    key: 'action',
                    fixed: 'right',
                    width: 100
                }]
			}
		},
		created() {
			this.getOrganizationList();
			if (this.id) {
				this.getDetail();
			}
		},
		methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			async onSubmit() {
				let postData = this.$deepClone(this.modelRef);
				if (this.modelRef.time) {
					postData.startTime = parseInt(this.modelRef.time[0].startOf('day').valueOf() / 1000);
					postData.endTime = parseInt(this.modelRef.time[1].endOf('day').valueOf() / 1000);
				}
				delete postData.time;
				if (postData.isUniversal === 0 && postData.cardRechargeActivityList) {
					postData.cardRechargeActivityList = postData.cardRechargeActivityList.map(item => {
						return { cardId: item }
					});
				} else {
					postData.cardRechargeActivityList = [];
				}
				if (postData.isCoupon) {
					if (!postData.ticketBatchNum && !postData.snackBatchNum) {
						return this.$message.warn('请输入含有券发放数量');
					}
					if (postData.ticketBatchNum && !postData.ticketBatchId.length) {
						return this.$message.warn('请选择兑换券批次');
					}
					if (!postData.ticketBatchNum && postData.ticketBatchId.length) {
						return this.$message.warn('请输入兑换券发放数量');
					}
					if (postData.snackBatchNum && !postData.snackBatchId.length) {
						return this.$message.warn('请选择卖品券批次');
					}
					if (!postData.snackBatchNum && postData.snackBatchId.length) {
						return this.$message.warn('请输入卖品券发放数量');
					}
				}
				if (postData.isCoupon) {
					postData.ticketBatchId = postData.ticketBatchId.length ? postData.ticketBatchId[0] : 0;
					postData.snackBatchId = postData.snackBatchId.length ? postData.snackBatchId[0] : 0;
				} else {
					postData.ticketBatchId = 0;
					postData.snackBatchId = 0;
					postData.ticketBatchNum = 0;
					postData.snackBatchNum = 0;
				}
				let ret;
				this.loading = true;
				if (this.isEdit) {
					ret = await updateRechargeActivity(postData);
				} else {
					ret = await saveRechargeActivity(postData);
				}
				this.loading = false;
				if (ret.code === 200) {
					if (this.isEdit) {
						this.$message.success('编辑成功');
					} else {
						this.$message.success('新增成功');
					}
					this.onBack(true);
				}
			},
			async getDetail() {
				this.loading = true;
				try {
					let ret = await getRechargeActivityDetail({
						id: this.id
					});
					this.loading = false;
					if (ret.code === 200) {
						ret.data.time = [this.moment(ret.data.startTime * 1000), this.moment(ret.data.endTime * 1000)];
						if (ret.data.cardRechargeActivityList) {
							ret.data.cardRechargeActivityList = ret.data.cardRechargeActivityList.map(item => {
								return item.cardId;
							});
						}
						if (ret.data.ticketBatch) {
							this.exchangeList = [{...ret.data.ticketBatch}];
						}
						if (ret.data.snackBatch) {
							this.snackList = [{...ret.data.snackBatch}];
						}
						delete ret.data.snackBatch;
						delete ret.data.ticketBatch;
						ret.data.ticketBatchId = ret.data.ticketBatchId ? [ret.data.ticketBatchId] : [];
						ret.data.snackBatchId = ret.data.snackBatchId ? [ret.data.snackBatchId] : [];
						this.getAllCinemaList(ret.data.organizationId);
						this.getCardPolicyList(ret.data.cinemaId, true);
						this.modelRef = ret.data;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				let ret = await getOrganizationList({
					page: 1,
					pageSize: 999999
				});
				if (ret.code === 200) {
					this.organizationList = ret.data.list;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.modelRef.cinemaId = undefined;
				// this.cardPolicyAllList = [];
				this.modelRef.cardRechargeActivityList = [];
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaList = ret.data.list;
				}
			},
			async getCardPolicyList(cinemaId, isDetail) {
				if (!isDetail) {
					// this.cardPolicyAllList = [];
					this.modelRef.ticketBatchId = [];
					this.exchangeList = [];
					this.modelRef.snackBatchId = [];
					this.snackList = [];
					this.modelRef.cardRechargeActivityList = undefined;
				}
				let ret = await getCardPolicyList({
					page: 1,
					pageSize: 999999,
					type: 3,
          useCinemaId: cinemaId ? cinemaId : undefined
				})
				if (ret.code === 200) {
					this.cardAllList = ret.data.list;
				}
			},
			onDelCoupon(type, index) {
				if (type === 1) {
					this.exchangeList.splice(index, 1);
					this.modelRef.ticketBatchId = [];
				}
				if (type === 3) {
					this.snackList.splice(index, 1);
					this.modelRef.snackBatchId = [];
				}
			},
			onCouponChange(type, data) {
				if (type === 1) {
					this.exchangeList = data || [];
				}
				if (type === 3) {
					this.snackList = data || [];
				}
			},
		}
	}
</script>

<style>
</style>
